import React, { FC, useState } from "react"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyHeading } from "@parachutehealth/canopy-heading"
import { CanopyRadioInputField } from "@parachutehealth/canopy-radio-input-field"
import { Formik } from "formik"
import Bell from "bell.svg"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import { updateIncomingOrdersNotificationPreference } from "../../api"

interface Props {
  setIncomingOrdersNotificationPreferenceMessage: (message: string) => void
}

const IncomingOrderTeamPushbackConfigurationsModal: FC<Props> = ({
  setIncomingOrdersNotificationPreferenceMessage,
}) => {
  const [open, setOpen] = useState(true)

  const onClose = () => setOpen(false)
  const onSubmit = (values, actions) => {
    actions.setSubmitting(false)
    updateIncomingOrdersNotificationPreference(
      values.incomingOrdersNotificationPreference
    ).then((res) => {
      if (res.status === 200) {
        if (values.incomingOrdersNotificationPreference === "notify") {
          setIncomingOrdersNotificationPreferenceMessage(
            "You've been added to a team to be notified for incoming orders"
          )
        } else if (
          values.incomingOrdersNotificationPreference === "do-not-notify"
        ) {
          setIncomingOrdersNotificationPreferenceMessage(
            "You've opted out of notifications for incoming orders"
          )
        }
      }
    })
    onClose()
  }

  return (
    <Formik
      initialValues={{ incomingOrdersNotificationPreference: undefined }}
      onSubmit={onSubmit}
      validate={(values) => {
        const errors: { incomingOrdersNotificationPreference?: string } = {}
        if (!values.incomingOrdersNotificationPreference) {
          errors.incomingOrdersNotificationPreference = "Select an option"
        }
        return errors
      }}
    >
      {({ errors, handleChange, handleSubmit, isSubmitting, values }) => {
        return (
          <CanopyDialogModal
            open={open}
            headerOverride={
              <CanopyFlex
                direction="column"
                alignItems="center"
                style={{ textAlign: "center" }}
              >
                <img src={Bell} className="canopy-mbe-8x" />
                <CanopyHeading size="xLarge" level={2}>
                  Never miss an order for your patients
                </CanopyHeading>
                Opt in to notifications for orders from suppliers that require
                more information
              </CanopyFlex>
            }
            onClose={onClose}
            primaryFooterButton={
              <CanopyButton
                variant="primary"
                type="submit"
                onClick={handleSubmit as any}
                loading={isSubmitting}
              >
                Confirm
              </CanopyButton>
            }
            secondaryFooterButton={
              <CanopyButton variant="secondary" onClick={onClose}>
                Close
              </CanopyButton>
            }
          >
            <form onSubmit={handleSubmit}>
              <CanopyRadioInputField
                label="Notify for incoming orders"
                hiddenLabel
                name="incomingOrdersNotificationPreference"
                value={values.incomingOrdersNotificationPreference}
                onChange={handleChange}
                feedbackMessage={
                  errors.incomingOrdersNotificationPreference as string
                }
                options={[
                  {
                    label: "Notify me for incoming orders from suppliers",
                    description:
                      "You’ll follow and be notified for incoming orders at your facility",
                    value: "notify",
                  },
                  {
                    label: "Don’t notify me for incoming orders from suppliers",
                    value: "do-not-notify",
                  },
                ]}
              />
            </form>
          </CanopyDialogModal>
        )
      }}
    </Formik>
  )
}

export default IncomingOrderTeamPushbackConfigurationsModal
