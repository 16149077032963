import React from "react"
import * as styles from "./SalesCollaborationToolsSaved.module.scss"
import SalesCollaborationToolsScreen from "../SalesCollaborationTools/components/SalesCollaborationToolsScreen"
import { Option } from "components/SupplierOrganizationDashboard/LeftSidebar"

export interface Props {
  supplierOrgExternalId: string
  salesTeamCollaborationToolsSavedMainApp?: boolean
}

const SalesCollaborationToolsSaved = (props: Props) => {
  return (
    <SalesCollaborationToolsScreen
      {...props}
      screen={Option.Saved}
      headerContent={
        <>
          <h1>
            <span className={styles.headerText}>Saved</span>
          </h1>
          <div className={styles.subheaderText}>
            These are facilities that you have saved.
          </div>
        </>
      }
    />
  )
}

export default SalesCollaborationToolsSaved
