import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import debounce from "awesome-debounce-promise"
import SearchFilter from "components/SearchFilter"
import SelectFilter from "components/SelectFilter"
import React, { useCallback, useMemo, useState } from "react"
import LeftSidebar, {
  Option,
} from "../../../components/SupplierOrganizationDashboard/LeftSidebar"
import { EventCategory, trackEvent } from "../../../utilities/tracking"
import { inviteReferralUrl, searchSalesReps, searchZipCodes } from "../api"
import * as styles from "./SalesCollaborationTools.module.scss"
import { SalesCollaborationToolsLegendModal } from "./SalesCollaborationToolsLegendModal"
import SalesCollaborationToolsTable from "./SalesCollaborationToolsTable"
import { SalesRep } from "../sharedTypes"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import { salesCollaborationToolsNetworkUrl } from "components/SupplierOrganizationDashboard/urls"

export interface Props {
  supplierOrgExternalId: string
  salesTeamCollaborationToolsSavedMainApp?: boolean
  salesTeamCollaborationToolsLowEngagementMainApp?: boolean
  headerContent: React.ReactNode
  screen: Option.Network | Option.Saved | Option.LowEngagement
}

type Action = {
  text: string
  onClick: () => void
}

type ErrorNoticeProps = {
  title: string
  message?: string
  actions?: Action[]
}

const backToNetwork = () => {
  window.location.href = salesCollaborationToolsNetworkUrl()
}
const SalesCollaborationToolsScreen = (props: Props) => {
  const {
    supplierOrgExternalId,
    salesTeamCollaborationToolsSavedMainApp,
    headerContent,
    screen,
  } = props

  const [modalVisible, setModalVisible] = useState(false)
  const [search, setSearch] = useState<string>("")
  const [zipCodes, setZipCodes] = useState<string[]>([])
  const [salesReps, setSalesReps] = useState<string[]>([])

  const [errorNotice, setErrorNotice] = useState<ErrorNoticeProps>()
  const handleError = useCallback(() => {
    setErrorNotice({
      title:
        "Network issue detected. Please check your connection and try again.",
      message: "Please try again later. If the issue persists, please....",
      actions: [
        {
          text: "Refresh page",
          onClick: window.location.reload,
        },
      ],
    })
  }, [])

  const openModal = () => {
    setModalVisible(true)
  }

  const closeModal = () => {
    setModalVisible(false)
  }

  const fetchZipCodeOptions = useCallback(
    async (searchTerm: string) => {
      const res = await searchZipCodes({
        searchTerm,
        supplierOrgExternalId,
        screen,
      })

      return res.map((zip) => ({
        label: zip,
        value: zip,
      }))
    },
    [supplierOrgExternalId, screen]
  )

  const debouncedSearchZip = useMemo(
    () =>
      debounce(fetchZipCodeOptions, 400, {
        leading: false,
      }),
    [fetchZipCodeOptions]
  )

  const onZipChange = ({ zipCode }: { zipCode: string[] }) => {
    setZipCodes(zipCode)
  }

  const fetchSalesRepOptions = useCallback(
    async (searchTerm: string) => {
      const res: SalesRep[] = await searchSalesReps({
        searchTerm,
        supplierOrgExternalId,
        screen,
      })

      return res.map((salesRep) => ({
        label: salesRep.name,
        value: salesRep.externalId,
      }))
    },
    [supplierOrgExternalId, screen]
  )

  const debouncedSearchSalesRep = useMemo(
    () =>
      debounce(fetchSalesRepOptions, 400, {
        leading: false,
      }),
    [fetchSalesRepOptions]
  )

  const onSalesRepChange = ({ salesRep }: { salesRep: string[] }) => {
    setSalesReps(salesRep)
  }

  const filters = useMemo(
    () => ({
      search,
      zipCodes,
      salesReps,
    }),
    [search, zipCodes, salesReps]
  )

  return (
    <div className={styles.mainContainer}>
      <LeftSidebar active={screen} />
      <SalesCollaborationToolsLegendModal
        onClose={closeModal}
        visible={modalVisible}
      />
      <div className="row mt-n3">
        {errorNotice ? <CanopyNotice variant="error" {...errorNotice} /> : null}
        <div className="canopy-mbs-12x col-12">
          {screen === Option.Network ? null : (
            <CanopyButton
              as="a"
              href={salesCollaborationToolsNetworkUrl()}
              style={{ backgroundColor: "transparent" }}
              variant="tertiary"
              iconStart="arrow-left"
              onClick={backToNetwork}
            >
              Back to network
            </CanopyButton>
          )}
          {headerContent}
          <hr />
          <CanopyFlex
            direction="row"
            justifyContent="space-between"
            className="canopy-mb-8x"
          >
            <CanopyFlex direction="row">
              <SearchFilter
                value={search}
                onChange={setSearch}
                placeholder="Search facility or address"
              />

              <SelectFilter
                initialValues={zipCodes}
                label="ZIP code"
                name="zipCode"
                fetchOptions={debouncedSearchZip}
                size="large"
                singular
                onChange={onZipChange}
              />

              <SelectFilter
                initialValues={salesReps}
                label="Sales Reps"
                name="salesRep"
                fetchOptions={debouncedSearchSalesRep}
                size="large"
                singular
                onChange={onSalesRepChange}
              />
            </CanopyFlex>
            <CanopyFlex direction="row" justifyContent="flex-end">
              <CanopyButton
                iconStart="circle-info"
                onClick={openModal}
                size="small"
                variant="tertiary"
              >
                Legend
              </CanopyButton>
              <CanopyButton
                as="a"
                href={inviteReferralUrl(supplierOrgExternalId)}
                size="small"
                variant="primary"
                target="_blank"
                onClick={() => {
                  trackEvent(
                    EventCategory.SalesCollaborationTools,
                    `${screen.toLocaleLowerCase()}-tab-invite-referral`
                  )
                }}
              >
                Invite referral
              </CanopyButton>
            </CanopyFlex>
          </CanopyFlex>
          <SalesCollaborationToolsTable
            supplierOrgExternalId={supplierOrgExternalId}
            salesTeamCollaborationToolsSavedMainApp={
              salesTeamCollaborationToolsSavedMainApp
            }
            onError={handleError}
            filters={filters}
            screen={screen}
          />
        </div>
      </div>
    </div>
  )
}

export default SalesCollaborationToolsScreen
