import { clinicalPath } from "../../routes"
import { RouteComponentProps } from "react-router"

export const LEGAL_WARNING =
  "You are responsible for ensuring that any patient information submitted by you is accurate and correct. Parachute does not provide recommendations related to the appropriateness of any order or plan of care."

export const CRITERIA_NOT_MET =
  "Based on the provided information, evidence may not support the medical necessity of the product or service. You may still submit the order for further review by the payor. To support your submission, please upload any additional documentation that may assist in the medical review process."

export const CRITERIA_MET =
  "Evidence supports the medical necessity of the product or service. Please select the signing clinician and ensure all details are accurate before final submission."

export const SUPPLIER_CRITERIA_MET =
  "Evidence supports the medical necessity of the product or service. Once the order is accepted, complete the health plan authorization request. The answers and documentation provided here will be included authorization request to determine if the authorization is approved."

export const SUPPLIER_CRITERIA_NOT_MET =
  "Based on the provided information, evidence may not support the medical necessity of the product or service. You can still accept the order and request authorization for further review by the health plan. To support your submission, please upload any additional documentation that may assist in the medical review process."

export type FillablePdfPathRouteProps = RouteComponentProps<{
  documentationRequirementExternalId
}>
export const fillablePdfPathMatcher = (): string =>
  `${clinicalPath()}/pdfs/:documentationRequirementExternalId?`
export const fillablePdfPath = (
  documentationRequirementExternalId?: string
): string => `${clinicalPath()}/pdfs/${documentationRequirementExternalId}`
