import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { CanopyRadioInputField } from "@parachutehealth/canopy-radio-input-field"
import { CanopyForm } from "@parachutehealth/canopy-form"
import DatePickerInput, { formatDate } from "components/input/DatePickerInput"
import React, { useState } from "react"
import { Formik, FormikValues } from "formik"
import {
  CarrierLineItemAuthorization,
  Maybe,
} from "../../../../../graphql/__generated__/graphql"
import * as styles from "./SupplierLineItemEditForm.module.scss"
import { CanopyFormFieldGroup } from "@parachutehealth/canopy-form-field-group"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { formatHcpcsModifiersObject } from "../../utilities/hcpcsModifiersFormatters"
import { format, SERVER_DATE_FORMAT } from "utilities/date"
import { CanopyCheckboxInput } from "@parachutehealth/canopy-checkbox-input"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import { isEmpty } from "lodash"

export interface LineItemFormProps {
  onCancel: () => void
  onDelete?: (lineItemId: string) => void
  onSubmit: (values: SubmitLineItemValues) => void
  lineItem: CarrierLineItemAuthorization | null
  formAction: FormActions
  reauth?: boolean
}

export interface SubmitLineItemValues {
  hcpcs: string
  hcpcsDetails?: any
  isMiscHcpcs?: boolean
  quantity: number
  startDate: string
  endDate: string
  priority: string
  previousAuthorizationNumber?: string
}

interface LineItemFormValues {
  hcpcs: string
  hcpcsModifiers?: any
  hcpcsDescription: string
  applyDatesToAllLineItems: boolean
  isMiscHcpcs?: boolean
  quantity: number | string
  startDate: string
  endDate: string
  priority: Maybe<string> | undefined
  previousAuthorizationNumber?: string | undefined
}

export enum FormActions {
  CREATE,
  EDIT,
}

const PRIORITY_OPTIONS = [
  {
    label: "Routine",
    value: "routine",
  },
  {
    label: "Urgent",
    value: "urgent",
    description:
      "Defined as requiring immediate action to prevent a serious deterioration of a member’s health that results from an unforeseen illness or an injury, or could jeopardize the ability of the individual to regain maximum function.",
  },
]

export const SupplierLineItemForm = ({
  onCancel,
  onSubmit,
  onDelete,
  lineItem,
  formAction,
  reauth,
}: LineItemFormProps) => {
  const hcpcDetailsString = !isEmpty(lineItem?.hcpcsModifiers)
    ? lineItem?.hcpcsModifiers?.join(", ")
    : undefined

  const initialValues: LineItemFormValues = {
    hcpcsModifiers: hcpcDetailsString,
    applyDatesToAllLineItems: false,
    hcpcs: lineItem?.hcpcs || "",
    hcpcsDescription: lineItem?.hcpcsDescription || "",
    quantity: lineItem?.quantity || "",
    startDate: formatDate(lineItem?.startDate),
    endDate: formatDate(lineItem?.endDate),
    priority: lineItem?.priority,
  }
  if (reauth) {
    initialValues["previousAuthorizationNumber"] =
      lineItem?.previousAuthorizationNumber || ""
  }

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const validate = (values: FormikValues) => {
    const errors = {}
    if (!values.hcpcs) {
      errors["hcpcs"] = "HCPCS required"
    }

    if (formAction === FormActions.CREATE && !values.hcpcsDescription) {
      errors["hcpcsDescription"] = "Description of HCPCS required"
    }

    if (!values.quantity) {
      errors["quantity"] = "Units required"
    }

    if (!values.startDate) {
      errors["startDate"] = "Start date required"
    }

    if (!values.endDate) {
      errors["endDate"] = "End date required"
    }
    if (
      !!values.startDate &&
      !!values.endDate &&
      new Date(values.startDate) >= new Date(values.endDate)
    ) {
      errors["endDate"] = "End date must be after start date"
    }

    if (!values.priority) {
      errors["priority"] = "Selection required"
    }

    if (reauth && !values.previousAuthorizationNumber) {
      errors["previousAuthorizationNumber"] =
        "Previous authorization number required"
    }
    return errors
  }

  const handleOnSubmit = async (values: FormikValues) => {
    const {
      applyDatesToAllLineItems,
      hcpcs,
      hcpcsModifiers,
      hcpcsDescription,
      quantity,
      startDate,
      endDate,
      priority,
      previousAuthorizationNumber,
    } = values

    const hcpcsDetails = hcpcsModifiers
      ? formatHcpcsModifiersObject(hcpcsModifiers)
      : undefined

    const valuesToSubmit = {
      applyDatesToAllLineItems,
      hcpcs,
      quantity,
      startDate: format(new Date(startDate), SERVER_DATE_FORMAT),
      endDate: format(new Date(endDate), SERVER_DATE_FORMAT),
      priority,
      previousAuthorizationNumber,
      hcpcsDetails,
    }
    if (!!hcpcsDescription) {
      valuesToSubmit.hcpcsDetails = {
        ...(valuesToSubmit.hcpcsDetails || {}),
        description: hcpcsDescription,
      }
    }

    if (formAction === FormActions.CREATE) {
      valuesToSubmit["isMiscHcpcs"] = true
    }

    onSubmit(valuesToSubmit)
  }

  const handleOnDelete = () => {
    if (onDelete && lineItem) {
      onDelete(lineItem.id)
    }
    setShowDeleteConfirmation(false)
  }

  return (
    <>
      <div className="canopy-typography-body-medium">
        Changes made here will NOT be reflected on the order.
      </div>
      <br />
      <div className="canopy-typography-body-medium canopy-mb-12x">
        <span className={styles.redText}>*</span> Indicates a required field
      </div>

      <Formik
        onSubmit={handleOnSubmit}
        initialValues={initialValues}
        validate={validate}
        enableReinitialize
      >
        {({ handleSubmit, handleChange, values, errors, touched }) => {
          return (
            <CanopyForm onSubmit={handleSubmit}>
              <CanopyTextInputField
                name="hcpcs"
                label="HCPCS"
                className={styles.inputField}
                onChange={handleChange}
                value={values.hcpcs}
                required
                feedbackMessage={
                  touched.hcpcs ? (errors.hcpcs as string) : undefined
                }
              />
              <CanopyTextInputField
                name="hcpcsModifiers"
                label="HCPCS modifiers"
                className={styles.inputField}
                description="If multiple, separate by comma"
                value={values.hcpcsModifiers}
                onChange={handleChange}
              />
              {(formAction === FormActions.CREATE || lineItem?.isMiscHcpcs) && (
                <CanopyTextInputField
                  name="hcpcsDescription"
                  label="Description of HCPCS"
                  value={values.hcpcsDescription}
                  onChange={handleChange}
                  required
                  feedbackMessage={
                    touched.hcpcsDescription
                      ? (errors.hcpcsDescription as string)
                      : undefined
                  }
                />
              )}
              <CanopyFlex className={styles.formSection}>
                <CanopyTextInputField
                  name="quantity"
                  label="Units"
                  value={values.quantity.toString()}
                  onChange={handleChange}
                  type="number"
                  required
                  feedbackMessage={
                    touched.quantity ? (errors.quantity as string) : undefined
                  }
                />
              </CanopyFlex>
              {reauth && (
                <section className={styles.formSection}>
                  <CanopyTextInputField
                    name="previousAuthorizationNumber"
                    label="Previous auth number"
                    value={values.previousAuthorizationNumber}
                    onChange={handleChange}
                    required
                    feedbackMessage={
                      touched.previousAuthorizationNumber
                        ? (errors.previousAuthorizationNumber as string)
                        : undefined
                    }
                  />
                </section>
              )}
              <CanopyFlex className={styles.formSection}>
                <CanopyFormFieldGroup
                  label="Start date"
                  id="startDate"
                  required
                  feedbackMessage={
                    touched.startDate ? (errors.startDate as string) : undefined
                  }
                >
                  {({ primaryControlId }) => (
                    <DatePickerInput
                      id={primaryControlId}
                      name="startDate"
                      value={values.startDate}
                      onChange={handleChange}
                    />
                  )}
                </CanopyFormFieldGroup>

                <CanopyFormFieldGroup
                  label="End date"
                  id="endDate"
                  required
                  feedbackMessage={
                    touched.endDate ? (errors.endDate as string) : undefined
                  }
                >
                  {({ primaryControlId }) => (
                    <DatePickerInput
                      id={primaryControlId}
                      name="endDate"
                      value={values.endDate}
                      onChange={handleChange}
                    />
                  )}
                </CanopyFormFieldGroup>
              </CanopyFlex>
              {formAction === FormActions.EDIT && (
                <CanopyCheckboxInput
                  className={styles.checkboxInput}
                  label="Apply start and end dates to all line items"
                  onChange={handleChange}
                  defaultChecked={values.applyDatesToAllLineItems}
                  name="applyDatesToAllLineItems"
                />
              )}

              <CanopyRadioInputField
                label="Priority"
                name="priority"
                options={PRIORITY_OPTIONS}
                value={values.priority ? values.priority : undefined}
                onChange={handleChange}
                className={styles.radioInput}
                required
                feedbackMessage={
                  touched.priority ? (errors.priority as string) : undefined
                }
              />

              <div className={styles.formButtonSection}>
                <CanopyButton variant="primary" type="submit">
                  Save
                </CanopyButton>
                <CanopyButton variant="tertiary" onClick={() => onCancel()}>
                  Cancel
                </CanopyButton>

                {formAction === FormActions.EDIT && (
                  <CanopyButton
                    variant="secondary"
                    onClick={() => setShowDeleteConfirmation(true)}
                    className={styles.alignRight}
                  >
                    Delete line item
                  </CanopyButton>
                )}
              </div>
            </CanopyForm>
          )
        }}
      </Formik>
      <CanopyDialogModal
        header={`Delete line item ${lineItem?.hcpcs}`}
        onClose={() => setShowDeleteConfirmation(false)}
        open={showDeleteConfirmation}
        primaryFooterButton={
          <CanopyButton variant="danger" onClick={handleOnDelete}>
            Delete
          </CanopyButton>
        }
        secondaryFooterButton={
          <CanopyButton
            variant="secondary"
            onClick={() => setShowDeleteConfirmation(false)}
          >
            Cancel
          </CanopyButton>
        }
        size="small"
      >
        Are you sure you would like to delete Prior auth line item:{" "}
        {lineItem?.hcpcs}? This action cannot be undone.
      </CanopyDialogModal>
    </>
  )
}
