import React from "react"
import { useParams } from "react-router-dom"
import { diagnosesPath } from "../routes"
import { Layout } from "../Layout/Layout"

const MedicalNecessity = () => {
  const { pendingOrderId, therapySelectionId } = useParams()

  return (
    <Layout>
      <Layout.Heading
        backlinkPath={diagnosesPath(pendingOrderId, therapySelectionId)}
        backlinkText="Back to diagnosis"
        headingText="Medical Necessity"
        showRequiredFieldKey
      />
    </Layout>
  )
}

export default MedicalNecessity
