import {
  CarrierAuthStatusesEnum,
  CarrierLineItemAuthorizationStatusEnum,
} from "../../../../graphql/__generated__/graphql"
import { Severity } from "sharedTypes"

export const AuthLineItemStatusToSeverity = {
  [CarrierLineItemAuthorizationStatusEnum.MissingRequiredInfo]: Severity.Danger,
  [CarrierLineItemAuthorizationStatusEnum.RequiredInfoComplete]:
    Severity.Success,
  [CarrierLineItemAuthorizationStatusEnum.PayorInReview]: Severity.Default,
  [CarrierLineItemAuthorizationStatusEnum.Approved]: Severity.Success,
  [CarrierLineItemAuthorizationStatusEnum.PartiallyApproved]: Severity.Warning,
  [CarrierLineItemAuthorizationStatusEnum.Denied]: Severity.Danger,
  [CarrierLineItemAuthorizationStatusEnum.Cancelled]: Severity.Danger,
  [CarrierLineItemAuthorizationStatusEnum.NoAdmit]: Severity.Danger,
  [CarrierLineItemAuthorizationStatusEnum.Rejected]: Severity.Danger,
}
export const AuthLineItemStatusToTitle = {
  [CarrierLineItemAuthorizationStatusEnum.MissingRequiredInfo]:
    "Missing required info",
  [CarrierLineItemAuthorizationStatusEnum.RequiredInfoComplete]:
    "Required info complete",
  [CarrierLineItemAuthorizationStatusEnum.PayorInReview]:
    "Pending payor review",
  [CarrierLineItemAuthorizationStatusEnum.Approved]: "Approved",
  [CarrierLineItemAuthorizationStatusEnum.PartiallyApproved]:
    "Partially approved",
  [CarrierLineItemAuthorizationStatusEnum.Denied]: "Denied",
  [CarrierLineItemAuthorizationStatusEnum.Cancelled]: "Cancelled",
  [CarrierLineItemAuthorizationStatusEnum.NoAdmit]: "No admit",
  [CarrierLineItemAuthorizationStatusEnum.Rejected]: "Request rejected",
}

export const AuthStatusToSeverity = {
  [CarrierAuthStatusesEnum.SupplierInProgress]: Severity.Default,
  [CarrierAuthStatusesEnum.PayorInReview]: Severity.Default,
  [CarrierAuthStatusesEnum.PayorReviewComplete]: "notice", // Custom blue pill with checkmark icon
  [CarrierAuthStatusesEnum.RequestRejected]: Severity.Danger,
}

export const AuthStatusToTitle = {
  [CarrierAuthStatusesEnum.SupplierInProgress]: "Supplier in progress",
  [CarrierAuthStatusesEnum.PayorInReview]: "Pending payor review",
  [CarrierAuthStatusesEnum.PayorReviewComplete]: "Payor review complete",
  [CarrierAuthStatusesEnum.RequestRejected]: "Request rejected",
}

export const AuthLineItemProblemStatuses = [
  CarrierLineItemAuthorizationStatusEnum.PartiallyApproved,
  CarrierLineItemAuthorizationStatusEnum.Denied,
  CarrierLineItemAuthorizationStatusEnum.Cancelled,
  CarrierLineItemAuthorizationStatusEnum.NoAdmit,
]
