import React, { useState } from "react"
import { SubSectionHeader } from "../SectionHeader"
import {
  CanopyComboboxField,
  OptionItem,
} from "@parachutehealth/canopy-combobox-field"
import { searchSkus, updateCartItems } from "./api"
import type { CartItem, Sku } from "./api"
import { useCsrInboxState } from "../../../csrInboxStateContext"
import { useMutation, useQuery } from "@tanstack/react-query"

const formatSku = (sku: Sku): OptionItem => ({
  value: sku.id,
  label: sku.description,
})

const formatCartItem = (item: CartItem): OptionItem => ({
  value: item.skuId,
  label: item.description,
})

const useSkuSearch = (
  searchTerm: string,
  supplierOrganizationId: string,
  csrInboxStateId: string
) => {
  return useQuery({
    queryKey: ["skus", supplierOrganizationId, csrInboxStateId, searchTerm],
    queryFn: () =>
      searchSkus(supplierOrganizationId, csrInboxStateId, searchTerm),
    enabled: !!searchTerm,
  })
}

type Props = {
  initialCartItems: CartItem[]
}

export const OrderItems = ({ initialCartItems = [] }: Props) => {
  const csrInboxState = useCsrInboxState()
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedItems, setSelectedItems] = useState<OptionItem[]>(
    initialCartItems.map(formatCartItem)
  )
  const [feedbackMessage, setFeedbackMessage] = useState<string>()

  const { data: skus = [] } = useSkuSearch(
    searchTerm,
    csrInboxState.supplierOrganization.id,
    csrInboxState.id
  )

  const updateCartItemsMutation = useMutation({
    mutationFn: async (selectedSkus: OptionItem[]) => {
      return updateCartItems(
        csrInboxState.supplierOrganization.id,
        csrInboxState.id,
        selectedSkus.map((sku) => ({
          skuId: sku.value,
        }))
      )
    },
  })

  const handleSelect = (selectedOptions: OptionItem | OptionItem[] | null) => {
    if (!selectedOptions) return
    const options = Array.isArray(selectedOptions)
      ? selectedOptions
      : [selectedOptions]
    setSelectedItems(options)
    updateCartItemsMutation.mutateAsync(options).then((result) => {
      if (result.errors) {
        setFeedbackMessage(result.errors.base.join(", "))
      }
    })
  }

  return (
    <>
      <SubSectionHeader text="Order items" />
      <CanopyComboboxField
        label="Product search"
        placeholder="Search by description or hcpcs"
        options={skus.map(formatSku)}
        onInputChange={setSearchTerm}
        onChange={handleSelect}
        feedbackMessage={feedbackMessage}
        value={selectedItems}
        multiple
      />
    </>
  )
}
